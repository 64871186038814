import { graphql, useStaticQuery } from "gatsby"

export default function useHowItWorksData() {
  const data = useStaticQuery(graphql`
    query getHowItWorksDataDos {
      markdownRemark(frontmatter: {url: {eq: "individual-works"}}) {
    id
    html
    frontmatter {
      title
    }
  }
  }
  `)
  return data
}
