import React from "react"
import useHowItWorksData from "../../static_queries/b2c/useHowItWorksData"
import HtmlPage from "../../components/HtmlPage"
import styled from "styled-components"

const StyledHowItWorks = styled.div`
  .html-page {
    
    p:last-of-type > img {
      margin-top: 40px;
      height: 400px;
    }
  }
  @media only screen and (min-width: 550px) {
    .html-page {
      blockquote {
        width: 60%;
        margin-top: 120px;
        float: right;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
`

export default function HowItWorks() {
  const data = useHowItWorksData()
  return <StyledHowItWorks className="how-it-works">
  <HtmlPage  b2c={true} data={data}/>
  </StyledHowItWorks>
}
